@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap");
/* variables */
:root {
  --white: #ffffff;
  --salmon: #ee8b98;
  --light-blue: #8d99ae;
  --dark-blue: #1c4f82;
  --very-dark-blue: #161722;
  /* --light-red: #ef233c;
  --medium-red: #d90429; */
  /* --dark-red: #900018; */
  --dark-red: #dc3545;
  --light-red: #ff438b;
  --medium-red: #fd3466;

}
.red {
  color: var(--medium-red);
}
.large {
  font-size: 1.3rem;
  font-weight: bold;
}
/* general */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Roboto Mono", monospace !important;
  font-size: 1.1rem !important;
  color: #fff;
  background-color: var(--white);
  min-height: 100vh;
  letter-spacing: 1px !important;
  background-color: #edf2f4;
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg fill-opacity='0.06'%3E%3Cpolygon fill='%23e9c2cb' points='1600 160 0 460 0 350 1600 50'/%3E%3Cpolygon fill='%23e593a3' points='1600 260 0 560 0 450 1600 150'/%3E%3Cpolygon fill='%23e1637a' points='1600 360 0 660 0 550 1600 250'/%3E%3Cpolygon fill='%23dd3452' points='1600 460 0 760 0 650 1600 350'/%3E%3Cpolygon fill='%23D90429' points='1600 800 0 800 0 750 1600 450'/%3E%3C/g%3E%3C/svg%3E"); */
  background-attachment: fixed;
  background-size: cover;
  


  overflow:hidden;
  color:#fff;
  background:radial-gradient(92.01% 92.01% at 50%,at 7.99%,#231e43 0,#191630 86.98%,#15103e 100%);
  background:radial-gradient(92.01% 92.01% at 50% 7.99%,#231e43 0,#191630 86.98%,#15103e 100%);
}
.fixed {
  position: fixed;
}
h5 {
  font-size: 0.8rem !important;
  font-weight: bold !important;
  color:#fff;
}
h3 {
  font-size: 2rem !important;
  font-weight: bold !important;
  color:#fff;

}
ul {
  list-style: none;
  color:#fff;

}
a {
  color: var(--very-dark-bluedark-blue) !important;
}
a:focus {
  box-shadow: none !important;
}
a.navbarLink {
  position: relative;
  text-decoration: none;
  padding: 0 15px;
  padding-bottom: 5px;
  color: aqua;
}
a.navbarLink:hover {
  transition: color 0.2s ease;
}
a.navbarLink::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  bottom: -3px;
  left: 0;
  background-color: var(--medium-red);
  transform: scaleX(0);
  transition: transform 0.2s ease;
}
a.navbarLink:hover::before {
  transform: scaleX(1);
}


.lastUpdated {
  color: yellow !important;
}
/* .btn {
  padding: 10px 20px !important;
  cursor: pointer;
  transition: background-color 0.2s ease;
  color: var(--white) !important;
  border: none !important;
  border-radius: 0 !important;
}
.btn-primary {
  background-color: var(--medium-red) !important;
}
.btn-primary:hover {
  background-color: var(--dark-red) !important;
} */
.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 80%;
  color:#fff;

}
.containerHeader {
  border-bottom: 2px solid #fd3466;
  width: 100%;
  padding-bottom: 10px;
  margin-right: auto;
  margin-bottom: 90px;
  margin-top: 150px;
  
}
.containerSubHeader {
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 150px;
  margin-top: 150px;
  display: flex;
  justify-content: flex-end;
  
}
/* intro */
.css-typing {
  top: 0;
  left: 0;
  /* background-color: var(--white); */
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  font-size: 3rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeOut 1s ease forwards;
  animation-delay: 2200ms;
  /* background-color: #edf2f4; */
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg fill-opacity='0.06'%3E%3Cpolygon fill='%23e9c2cb' points='1600 160 0 460 0 350 1600 50'/%3E%3Cpolygon fill='%23e593a3' points='1600 260 0 560 0 450 1600 150'/%3E%3Cpolygon fill='%23e1637a' points='1600 360 0 660 0 550 1600 250'/%3E%3Cpolygon fill='%23dd3452' points='1600 460 0 760 0 650 1600 350'/%3E%3Cpolygon fill='%23D90429' points='1600 800 0 800 0 750 1600 450'/%3E%3C/g%3E%3C/svg%3E"); */
  background-attachment: fixed;
  background-size: cover;
  background:radial-gradient(92.01% 92.01% at 50%,at 7.99%,#231e43 0,#191630 86.98%,#15103e 100%);
}
.css-typing div {
  min-width: 500px;
}
.css-typing p {
  color: var(--medium-red);
}
.css-typing span {
  color: var(--very-dark-blue);
}
.css-typing p {
  border-right: 0.15em solid var(--medium-red);
  font-family: "Courier";
  white-space: nowrap;
  /* overflow: hidden; */
}
.css-typing p:nth-child(1) {
  width: 6em;
  -webkit-animation: type 0.5s steps(40, end);
  animation: type 0.5s steps(40, end);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.css-typing p:nth-child(2) {
  width: 9em;
  opacity: 0;
  -webkit-animation: type2 1s steps(40, end);
  animation: type2 1s steps(40, end);
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.css-typing p:nth-child(3) {
  width: 12em;
  opacity: 0;
  -webkit-animation: type3 1.5s steps(40, end);
  animation: type3 1.5s steps(40, end);
  -webkit-animation-delay: 1.7s;
  animation-delay: 1.7s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
/* scrollbar */
body::-webkit-scrollbar {
  width: 0.7rem;
}
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--medium-red);
  transition: background-color 0.2s ease;
}
body::-webkit-scrollbar-thumb:hover {
  background-color: var(--dark-red);
}
/* navbar */
.navbar {
  position: fixed !important;
  width: 100%;
  z-index: 2000;
  /* background-color: var(--white); */
  display: flex;
  align-items: center;
  padding: 15px 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  border-bottom: 2px solid var(--very-dark-blue);



  color:#fff;
  background: fixed;
  background-color:rgb(31, 27, 59);
  
}
.mainLogo {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-right: auto;
  min-width: 210px;
  padding-left: 10px;
  transition: border-color 0.2s ease;
  animation: slideInLeft 1.4s ease;
}
a.firstContainer,
a.lastContainer {
  text-decoration: none;
  color: var(--very-dark-blue);
}
.mainLogo span {
  font-size: 1.3rem;
}
.lastName {
  color: var(--medium-red);
  transition: color 0.2s ease;
}
.firstName {
  color: var(--medium-red);
  transition: color 0.2s ease;
}
.navbarLinks ul {
  display: flex;
  justify-content: center;
  animation: slideInRight 1.5s ease;
  color: aqua;
}
.navbarLinks ul li {
  font-size: 1rem;
  color: aqua;
}
/* menu */
.menu {
  display: flex;
  justify-content: center;
  background-color: var(--white);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  height: 100%;
  width: 80%;
  border-left: 6px solid var(--medium-red);
  border-top: 6px solid var(--medium-red);
  animation: menuSlide 0.2s ease;
}
.closeIconContainer {
  position: absolute;
  top: -7px;
  right: 5px;
  cursor: pointer;
}
.closeIconContainer:hover .closeIcon {
  color: var(--medium-red);
}
.closeIcon {
  font-size: 6rem;
  transition: all 0.2s ease;
  color: var(--very-dark-blue);
}
.hamburgerIconContainer {
  cursor: pointer;
  display: none;
}
.hamburgerIconContainer:hover .hamburgerIcon {
  color: var(--medium-red);
}
.hamburgerIcon {
  padding-right: max;
  font-size: 3rem;
  transition: all 0.2s ease;
}
.menu .menuLinksContainer {
  width: 100%;
  margin-top: 200px;
}
.menuLinksContainer li {
  margin-top: 30px;
  color: black  ;
}
.menuLinksContainer li a {
  font-size: 1.2rem;
  color: black;
}
.menuSocialLinksContainer{
  position: absolute;
  bottom:60px;
  display: flex;
}
.menuSocialLinksContainer .menuSocialIcon{
  font-size: 1.9rem;
  margin-right: 20px;
  cursor: pointer;
  color: var(--very-dark-blue);
  transition: all 0.2s ease-in-out;
}
.menuSocialIconContainer:hover .menuSocialIcon {
  color: var(--medium-red);
  transform: scale(1.1);
}
/* social */
.socialLinksContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  bottom: 0;
  left: -1%;
  animation: rise 2s ease;
}
.socialLinks {
  display: flex;
}
.socialIconContainer .socialIcon {
  font-size: 1.9rem;
  margin-bottom: 20px;
  cursor: pointer;
  color: rgb(255, 88, 88);
  transition: all 0.2s ease-in-out;
}
.socialIconContainer:hover .socialIcon {
  color: var(--medium-red);
  transform: scale(1.1);
}
.line {
  border: 1px solid rgb(255, 88, 88);
  height: 150px;
  margin-top: 20px;
}
/* email */
.emailContainer {
  object-fit: contain;
  position: fixed;
  width: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 2%;
  animation: rise 2s ease;
}
.email {
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem !important;
  margin-left: 5px;
  margin-bottom: 140px;
  cursor: pointer;
  transform: rotate(90deg);
  color: rgb(255, 88, 88) !important;
  transition: all 0.2s ease-in-out;
  text-decoration: none !important;
}
.email:hover {
  color: var(--medium-red) !important;
  font-size: 1.05rem !important;
}
.copyIcon {
  margin-bottom: 170px;
  cursor: pointer;
  font-size: 1.4rem;
  transition: all 0.2s ease;
}
.copyIcon:hover {
  font-size: 1.6rem;
  color: var(--medium-red);
}
.copySuccess {
  margin-bottom: 10px;
  margin-right: 20px;
}
/* hero */
.heroContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - (86px));
  animation: fadeIn 1.5s ease;
}
.darkOverlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  height: 20000px;
  width: 100vw;
  z-index: 2222;
}
.innerHero {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 86px;
  width: 80%;
  height: 60vh;
  min-height: 600px;
  max-width: 1400px;
  color: var(--white);
}
.leftInnerHero {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex: 1;
  height: 100%;
  color: var(--very-dark-blue);
}
.heroIntroHeading {
  text-align: center;
  margin-bottom: 20px;
}
.heroIntroParagraph {
  text-align: center;
}
.avatarContainer {
  position: relative;
  width: 260px;
  height: 260px;
  border: 2px solid var(--very-dark-blue);
  overflow: hidden;
  background-size: cover;
  margin: 20px 0;
  filter: saturate(0.5);
  transition: filter 0.2s ease;
  z-index: 2;
}
.avatarContainer:hover {
  filter: saturate(1);
}
.avatarContainer img {
  transition: transform 0.2s ease;
}
.avatarContainer img:hover {
  transform: scale(1.05);
}
.avatarContainerShadow {
  position: absolute;
  height: 260px;
  width: 260px;
  margin-left: 100px;
  margin-bottom: 250px;
  background-color: var(--medium-red);
  border: 2px solid var(--very-dark-blue);
  transition: background-color 0.2s ease;
}
.avatarContainerShadow:hover {
  background-color: var(--salmon);
  background-color: var(--dark-red);
}
.avatarContainer img {
  position: absolute;
  width: 100%;
}
.rightInnerHero {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 40px;
  padding-right: 0px;
  flex: 1;
  height: 100%;
  background-color: var(--very-dark-blue);
  line-height: 30px;
  z-index: 2;
  transition: all 0.3s ease-in-out;
  border-right: 15px solid var(--very-dark-blue);
  border-bottom: 15px solid var(--very-dark-blue);
}
.rightInnerHero:hover {
  border-color: var(--medium-red);
}
.firstNameHero {
  color: var(--medium-red);
}
.code {
  margin-left: 50px;
  font-size: 1.2rem;
}
.firstNameCode {
  animation: colorChange 2s ease-in-out;
}
.lastNameCode {
  animation: colorChange 2s ease-in-out;
  animation-delay: 2s;
}
.nationalityCode {
  animation: colorChange 2s ease-in-out;
  animation-delay: 4s;
}
.infoCode {
  animation: colorChange 2s ease-in-out;
  animation-delay: 6s;
}
.heroButton {
  text-decoration: none;
}
/* about me */
.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.aboutMeIntro {
  /* border: 2px solid var(--medium-red); */
  /* padding: 0px 300px; */
  /* color: var(--medium-red); */
  font-size: 1.3rem;
  font-weight: bold;
}
.aboutMe {
  line-height: 30px;
  backdrop-filter: blur(20px);
}
.aboutMe2 {
  max-height: 0;
  overflow: hidden;
  transition: 0.5s;
}
.grow {
  max-height: 1500px !important;
}
.grow2 {
  max-height: 3000px !important;
}
.readMore {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.chevronIcon {
  font-size: 2rem;
  margin-left: 5px;
}
.technologies {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 500px;
}
.technologyContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px;
  width: 80px;
  height: 80px;
  filter: saturate(0.4);
  backdrop-filter: blur(20px);
}
.technologyContainer img {
  width: 100%;
}
/* projects */

.project {
  display: flex;
  color: var(--white);
  max-width: 1600px;
  margin-bottom: 100px;
}
.project:nth-child(even) {
  flex-direction: row-reverse;
}
.projectLeft,
.projectRight {
  object-fit: contain;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.projectLeft {
  border: none;
}
.projectRight {
  padding: 30px;
  background-color: var(--very-dark-blue);
  justify-content: space-between;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px;
}
.projectImageContainer {
  overflow: hidden;
  background-size: cover;
  min-width: 400px;
  max-height: 400px;
  background-color: var(--white);
  border: 1px solid var(--very-dark-blue);
}
.projectImageContainer > :first-child {
  width: 100%;
  height: 100%;
}
.projectImageContainer img {
  width: 100%;
  height: 100%;
}
.projectTitle,
.projectType {
  margin-left: auto;
}
.projectTitle {
  font-size: 1.8rem;
  color: var(--medium-red);
  color: var(--light-red);
}
.projectDetails {
  text-align: right;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.projectTechs {
  display: flex;
  justify-content: flex-end;

  flex-wrap: wrap;
  margin-left: auto;
  line-height: 30px;
}
.triangleIcon {
  color: var(--medium-red);
  font-size: 1.1rem;
  margin-right: 2px;
}
.projectTechs small {
  background-color: var(--very-dark-blue);
  background-color: var(--white);
  color: var(--white);
  color: var(--very-dark-blue);
  padding: 0px 8px;
  margin-left: 5px;
  margin-top: 5px;
}
.projectLinks {
  display: flex;
  margin-left: auto;
  margin-top: 5px;
}
.repoBtn {
  margin-left: 5px;
}
.viewMoreProjects {
  margin-top: 50px;
}
/* other project */
.otherProjectsSubHeader {
  margin-top: 50px;
}
.otherProjectsContainer {
  max-height: 0;
  overflow: hidden;
  transition: 0.7s;
}
.otherProjectGrid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}
.otherProject {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  height: auto;
  background-color: var(--very-dark-blue);
  color: var(--white);
  font-size: 1rem;
  padding: 10px;
}
.otherProjectTop {
  display: flex;
}
.otherProjectImage {
  flex: 1;
  text-align: start;
}
.otherProjectIcon {
  color: var(--medium-red);
  font-size: 4rem;
}
.otherProjectText {
  text-align: end;
  flex: 1;
}
.otherProjectTitle {
  font-size: 1.3rem;
  font-weight: bold;
  color: var(--light-red);
}
.otherProjectTechs {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.otherTech {
  font-size: 0.8rem;
  margin-right: 5px;
  margin-top: 5px;
  color: var(--very-dark-blue);
  background-color: var(--white);
  padding: 0px 4px;
}
.otherProjectBottom {
  display: flex;
  flex-direction: column;

  text-align: end;
  height: 100%;
  font-size: 0.9rem;
}
.otherProjectDetails {
  margin-bottom: auto;
}

.otherProjectLinks a {
  font-size: 0.9rem;
  padding: 5px 10px !important;
}
/* carousel */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  /* filter: invert(1); */
  transform: scale(1.4);
  background-color: var(--very-dark-blue);
}
.carousel-indicators {
  filter: invert(1);
  opacity: 0;
}
/* contact me */
form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none !important;
  width: 100%;
  max-width: 600px;
}
input,
textarea {
  border: 2px solid var(--very-dark-blue);
  padding: 10px 15px;
  outline: none !important;
  width: 100%;
  letter-spacing: 1px;
  font-family: inherit;
}
form small {
  margin-right: auto;
  margin-bottom: 8px;
  margin-top: 8px;
}
input::-webkit-input-placeholder {
  color: var(--very-dark-blue);
  opacity: 0.4;
}
textarea::-webkit-input-placeholder {
  color: var(--very-dark-blue);
  opacity: 0.4;
}
#email {
  /* background-color: var(--white); */
}
#email:focus {
  border-color: var(--medium-red) !important;
}
#myEmail {
  background-color: var(--salmon);
  border: none;
}
p#myEmail{
  border: 2px solid var(--salmon);
  padding: 10px 15px;
  outline: none !important;
  width: 100%;
  letter-spacing: 1px;
  font-family: inherit;
  color: var(--white);
  text-align: start;
}
#myEmail::-webkit-input-placeholder {
  color: white;
  opacity: 0.8;
}
#textarea {
  margin-bottom: 10px !important;
  resize: none !important;
}
#textarea:focus {
  border-color: var(--medium-red);
}
.sendIcon {
  font-size: 1.2rem;
}
/* footer */
footer {
  margin-top: 200px;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 1px;
}
.goToTop{
  font-size: 1rem;
  text-decoration: none;
  transition: all 0.2s ease;
}
.goToTop:hover{
  color: var(--medium-red) !important;
}
.goToTop:hover .upArrowIcon{
  transform: scale(1.1);
}
.upArrowIcon{
  font-size: 1.9rem;
  /* margin-left:10px ; */
  color: rgb(255, 88, 88);
}

.upArrowIcon :hover {
  color: var(--medium-red);
  
}

footer small {
  display: block;
  margin-top: 30px;
  padding-bottom: 20px;
}
.githubLink {
  text-decoration: none;
  color: var(--medium-red) !important;
  transition: color 0.2s ease;
}
.githubLink:hover {
  color: var(--dark-red) !important;
}
/* animations */
@keyframes borderPulse {
  0% {
  }
  100% {
    border-color: transparent;
  }
}
@keyframes colorChange {
  0% {
    color: var(--white);
  }
  50% {
    color: var(--light-red);
  }
  100% {
    color: var(--white);
  }
}
/* typewriter credit to:https://codepen.io/daviddcarr/pen/XVyQMM */
@keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.15em solid var(--medium-red);
  }
  100% {
    border: none;
  }
}
@keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: 0.15em solid var(--medium-red);
  }
  100% {
    opacity: 1;
    border: none;
  }
}
@keyframes type3 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  50% {
    border-color: transparent;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes rise {
  0% {
    transform: translateY(2000px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideInRight {
  0% {
    transform: translateX(6000px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInLeft {
  0% {
    transform: translateX(-6000px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes menuSlide {
  0% {
    transform: translateX(5000px);
  }
  100% {
    transform: translateX(0);
  }
}
/* media queries */
@media (max-width: 1000px) {
  .innerHero {
    margin-top: 320px;
    margin-bottom: 150px;
    flex-direction: column;
  }
  .project {
    flex-direction: column-reverse;
  }
  .project:nth-child(even) {
    flex-direction: column-reverse;
  }
  .projectImageContainer {
    border-top: 2px solid var(--medium-red);
  }
  .extra {
    flex-direction: column;
  }
  .extraImageContainer {
    border-top: 2px solid var(--medium-red);
  }
  .copySuccess {
    margin-right: 40px;
  }
  .projectLinks {
    margin-top: 30px;
  }
  .extraLinks {
    margin-top: 30px;
  }
}
@media (max-width: 900px) {
}
@media (max-width: 800px) {
  body {
    font-size: 0.9rem !important;
  }
  .leftInnerHero {
    width: 100%;
  }
  .rightInnerHero {
    width: 100%;
    padding: 20px;
    padding-right: 0;
  }
  .socialLinksContainer {
    display: none;
  }
  .emailContainer {
    display: none;
  }
  .container {
    width: 100% !important;
  }
  .code {
    font-size: 1rem;
  }
  .aboutMeIntro {
    font-size: 1.1rem;
  }
  .projectDetails {
    font-size: 0.9rem;
  }
  .otherProjectDetails {
    font-size: 0.9rem;
  }
  .menu {
  }
  .navbarLinks {
    display: none;
    color: aqua;
  }
  .hamburgerIconContainer {
    display: block;
  }
}
@media (max-width: 700px) {
}
@media (max-width: 600px) {
  .innerHero {
    margin-top: 360px;
  }
  footer,.goToTop {
    font-size: 0.9rem;
  }
}
@media (max-width: 500px) {
  .large {
    font-size: 1.1rem;
  }
  .navbar {
    padding: 8px;
  }
  .innerHero {
    margin-top: 280px;
    width: 100%;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .avatarContainer {
    width: 200px;
    height: 200px;
  }
  .avatarContainerShadow {
    width: 200px;
    height: 200px;
  }
  .container {
    text-align: center;
  }
  .containerHeader {
    text-align: start;
  }
  .projectImageContainer {
    min-width: 300px;
  }
  .extraImageContainer {
    min-width: 300px;
  }

  .technologyContainer {
    width: 50px;
    height: 50px;
  }
  .projectRight {
    padding: 10px;
  }
  .extraRight {
    padding: 10px;
  }
  .hamburgerIcon {
    font-size: 2rem;
  }
  .closeIconContainer {
    right: -2px;
    top: 8px;
  }
  .closeIcon {
    font-size: 4rem;
  }
  .css-typing {
    font-size: 2rem;
  }
  .css-typing div {
    min-width: 300px;
  }
}
@media (max-width: 400px) {
  .innerHero {
    margin-top: 320px;
  }
  .code {
    font-size: 0.9rem;
  }
  .avatarContainer {
    width: 180px;
    height: 180px;
  }
  .avatarContainerShadow {
    width: 180px;
    height: 180px;
  }
  .projectDetails {
    font-size: 0.8rem;
  }
  .extraDetails {
    font-size: 0.8rem;
  }
  .otherProjectDetails {
    font-size: 0.8rem;
  }
}
@media (max-width: 300px) {
}

.otherProjectGrid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}


/* extras */

.extra {
  display: flex;
  color: black;
  max-width: 1600px;
  margin-bottom: 100px;
}

.extraLeft {
  flex: 1;
  display: flex;
  object-fit: contain;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  justify-content: space-between;
  /* box-shadow: #fd3466 0px 4px 16px; */
  /* background-color: rgba(255, 0, 0, 0.048); */
  color:#fff;

}
.extraLeft {
  border: none;
}
.extraImageContainer {
  overflow: hidden;
  background-size: cover;
  min-width: auto;
  max-height: 400px;
  background-color: var(--white);
  border: 1px solid var(--very-dark-blue);
}
.extraImageContainer > :first-child {
  width: 100%;
  height: 100%;
}
.extraImageContainer img {
  width: 100%;
  height: 100%;
}
.extraTitle {
  object-fit: contain;
  margin-right: auto;
  font-size: 1.8rem;
  color: var(--medium-red);
}
.extraDetails {
  text-align: left;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.extraPosition {
  margin-right: auto;
  font-size: 1.4rem;
  color: var(--light-red);
}

.extraHr {
  width: 100%;
  height: 2px;
  background-color: rgb(221, 255, 0);
  margin: 0px 0;
}

.extraDate {
  margin-right: auto;
  font-size: 1.1rem;
  color: aqua;
  margin-bottom: 1%;
}


.extraLinks {
  display: flex;
  margin-right: auto;
  margin-top: 5px;
}
.repoBtn {
  margin-left: 5px;
}

/* Technology */
.otherTechnology {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  height: 400px;
  background-color: var(--very-dark-blue);
  color: var(--white);
  font-size: 1rem;
  padding: 10px;
  box-shadow: var(--dark-red) 0px 3px 3px;
}

.otherTechnologyTop {
  display: flex;
}

.otherTechnologyText {
  text-align: left;
  flex: 1;
  color: white;
}

.otherTechnologyTitle {
  font-size: 1.3rem;
  font-weight: bold;
  color: var(--light-red);
}
/* .otherTechnologyTechs {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
} */
/* .otherTechno {
  text-align: left;
  font-size: 0.8rem;
  margin-right: auto;
  margin-top: 5px;
  color: black;
  padding: 0px 4px;
} */

.otherTechnologyBottom {
  display: flex;
  flex-direction: column;

  text-align: end;
  height: 100%;
  font-size: 0.9rem;
}








.gradient-text {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 100;
  letter-spacing: 2px;
  /* text-align: center; */
  display: inline-block;
  background-image: -webkit-linear-gradient(
    141.27deg,
    rgb(255, 144, 78) -4.24%,
    rgb(255, 89, 130) 21.25%,
    rgb(231, 38, 241) 44.33%,
    rgb(193, 219, 226) 83.46%
  );

  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: hue 7s infinite linear alternate-reverse;
  animation: hue 7s infinite linear alternate-reverse;
}

.gradient-text1 {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 100;
  letter-spacing: 2px;
  /* text-align: center; */
  display: inline-block;
  background-image: -webkit-linear-gradient(
    141.27deg,
    rgb(216, 104, 184) -4.24%,
    rgb(251, 60, 108) 21.25%,
    rgb(231, 38, 241) 44.33%,
    rgb(248, 127, 116) 83.46%
  );

  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: hue 15s infinite linear alternate-reverse;
  animation: hue 15s infinite linear alternate-reverse;
}

@-webkit-keyframes hue {
  from {
    -webkit-filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(-360deg);
  }
}
@keyframes hue {
  from {
    -webkit-filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(-360deg);
  }
}



 .btn {
  text-decoration:none;
  background:transparent;
  border:none;
  cursor:pointer;
  display:inline-flex;
  justify-content:center;
  padding:1rem;
  font-weight:600;
  font-size:1rem;
  line-height:1;
  white-space:nowrap;
  border-radius:.4em;
  transition:all .2s;
  border: none;
 }
 .btn-primary {
  color:#fff;
  background:radial-gradient(50% 50% at 50%,at 50%,#f36 0,#fa3667 100%);
  background:radial-gradient(50% 50% at 50% 50%,#f36 0,#fd3466 100%);
  box-shadow:0 4px 14px rgba(250,55,104,.48)
 }
 .btn-primary:hover {
  box-shadow:0 4px 28px rgb(253, 52, 102);
 }
 .btn-primary:focus {
  outline:none;
  box-shadow:0 4px 28px rgba(250,55,104,.64),0 0 0 2px #c2274e
 }



 
.aqua {
  color: aqua;
}


.btn-pgp {
  margin-left: 50px;
}


.lastUpdated {
  color: yellowgreen;
}